import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertAction, AlertType, ButtonType } from '../../enums/shared-enums';
import { Localization } from '../../localization/localization';
import { ButtonValue } from '../../Models/ag-models';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';

@Component({
  selector: 'app-drag-drop-file',
  templateUrl: './drag-drop-file.component.html',
  styleUrls: ['./drag-drop-file.component.scss']
})
export class DragDropFileComponent implements OnInit {
  captions: any;
  file: any;
  showProgress: boolean;
  progress: number;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  excelImport = import('xlsx');
  type: any;
  maxNoOfFiles : number;

  @Output() dropFailed:EventEmitter<any> = new EventEmitter();
  @Output() dataDropped: EventEmitter<{ fileName: string; content: string[] }> = new EventEmitter();
  @Output() saveAction: EventEmitter<any[]> = new EventEmitter();
  @Output() cancelAction: EventEmitter<any[]> = new EventEmitter();
  @Input() externalSaveEnabled;
  @Input() fileType;
  @Input() isMultiple;
  @Input() maxByte; 
  @Input('noOfFiles')
  set noOfFilesInput(value) {
    if (value) {
      this.maxNoOfFiles= value;
    }
  }


  constructor(private localization: Localization,private utils:CommonUtilities) {
    this.captions = this.localization.captions;
  }

  ngOnInit() {
    this.type = this.fileType ? this.fileType.toString() : '.xls,.xlsx,.xml,.zip,.txt,.csv,.csv,.bpmn,.doc,.docx,.dotx,.xltx,.ppt,.pptx,.potx,.ppsx,.pdf,.html,.jpeg,.jpg,.png,.gif';
    this.maxNoOfFiles = this.maxNoOfFiles ?? 9999;
    this.initlializePage();
  }


  initlializePage() {
    this.actionButton = {
      type: 'primary',
      label: this.captions.btn_save
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel,
    };
  }

  fileHandler(arg) {
    if(this.isMultiple){
      let validfilename = [];
      let INvalidfilename = [];
      if(arg.length>this.maxNoOfFiles){
        // let message = "Only "+this.maxNoOfFiles+" files can Be uploaded";
        let message = this.localization.replacePlaceholders(this.captions.err_maxfile, ['nooffiles'], [this.maxNoOfFiles]);
        this.utils.showAlert(message, AlertType.Warning, ButtonType.Ok);
        this.dropFailed.emit();
        return;
      }
      for (const list of arg) {
        const listName = list.name.split('.');
        const extsn = listName[listName.length-1].toUpperCase();
        if(this.type.toUpperCase().includes(extsn)){
          switch (extsn) {
            case '.csv':
              list.progress = 0;
              this.file = list;
              this.readFile(list);
              break;
            case '.xls, .xlsx':
              this.parseExcel(list);
              break;
            default:
              this.readPDF(list)
              break;
          }
          validfilename.push(list.name);
        }else{
          INvalidfilename.push(list.name);
        }
      }

      if(INvalidfilename.length > 0){ 
        let message = this.localization.replacePlaceholders(this.captions.err_invalidfile, ['nooffiles'], [INvalidfilename.length]);
        this.utils.showAlert(message, AlertType.Warning, ButtonType.Ok);
        this.dropFailed.emit();
      }
    }else{
      
      const listName = arg[0].name.split('.');
      const extsn = listName[listName.length-1].toUpperCase();
      if(this.type.toUpperCase().includes(extsn)){
        switch (this.type) {
          case '.csv':
            for (const item of arg) {
              item.progress = 0;
              this.file = item;
              this.readFile(item);
            }
            break;
          case '.xls, .xlsx':
            this.parseExcel(arg[0]);
            break;
          default:
            this.readPDF(arg[0])
            break;
        }
    }else{
      this.utils.showAlert(this.captions.err_attachValidDoc, AlertType.Warning, ButtonType.Ok);
      this.dropFailed.emit();
    }
    }

  }

  saveFile(arg) {
    this.saveAction.emit();
  }

  discardFile() {
    this.file = null;
    this.cancelAction.emit();
  }

  private readFile(file) {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (e) => {
      this.showProgress = true;      

    };
    reader.onprogress = (e) => {
      if (e.lengthComputable) {
        this.progress = (e.loaded / e.total) * 100;

      }
    };
    reader.onerror = (error) => {
      this.showProgress = false;
      console.warn('Error reading dropped file:');
      console.error(error);

    };
    reader.onloadend = () => {
      this.showProgress = false;
      const rateCSV: string = reader.result as string;
      const rows = rateCSV.toString().split(/\r?\n/);
      const fileName = this.file.name;
      if (!this.externalSaveEnabled) {
        this.file = null;
      }
      this.dataDropped.emit({ fileName, content: rows });
    };
  }

  parseExcel(file) {
    const fileName = file.name;
    let reader = new FileReader();
    reader.onload = (e) => {
      let data = (<any>e.target).result;
      this.excelImport.then(XLSX=>{
      let workbook = XLSX.read(data, {
        type: 'binary',
        cellDates: true
      },
      );
      workbook.SheetNames.forEach((function (sheetName) {
        // Here is your object  
        let XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { raw: false });
        let json_object = JSON.stringify(XL_row_object);

        // bind the parse excel file data to Grid  

        this.dataDropped.emit({ fileName, content: JSON.parse(json_object),sheetName });
      }).bind(this), this);
     })
    };

    reader.onerror = function (ex){
       //reader
    } ;
    reader.readAsBinaryString(file);
  }

  private readPDF(file) {
    const fileName = file.name;
    let reader = new FileReader();
    let that = this;
    reader.onloadend = (e) => {
      let data = (<any>e.target).result;
      if(file.size > this.maxByte&&this.maxByte)
      {
        this.utils.showAlert(this.captions.lbl_maxSizeExceeded, AlertType.Warning, ButtonType.Ok);
        this.dropFailed.emit();
      }else{
        this.dataDropped.emit({ fileName, content: data });
      }
   
    }

    reader.onerror = function (ex) {
      that.dropFailed.emit();
    };
    reader.readAsDataURL(file);
  }

  clearSelection(event) {
    event.target.value = '';
  }
}
